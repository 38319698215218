import React from "react";

import { LineItemFieldsFragment } from "../../fragments.graphql";

import { Headline } from "design-system";
import { ProductContainer } from "components/ProductContainer";
import { SimpleTable } from "components/SimpleTable";
import Decimal from "decimal.js";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";
import { RoundedCurrency } from "lib/credits";
import { Quantity } from "../../Quantity";

export const CreditPurchaseLineItem: React.FC<{
  lineItem: LineItemFieldsFragment & { __typename: "CreditPurchaseLineItem" };
}> = ({ lineItem }) => {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  return (
    <div className="p-12">
      <ProductContainer
        title={
          <Headline
            level={5}
            className={
              isEmbeddableDashboard
                ? "text-default-font"
                : "text-deprecated-primary-dark"
            }
          >
            {lineItem.display_name}
          </Headline>
        }
        groupKey={null}
      >
        <div className="-mb-12  p-12 pb-0">
          <SimpleTable
            noBottomBorder
            data={lineItem.sub_line_items}
            columns={[
              {
                header: "Description",
                render: (subLineItem) => {
                  return subLineItem.display_name;
                },
              },
              {
                header: "Quantity",
                alignment: "right",
                render: (subLineItem) => (
                  <Quantity quantity={subLineItem.quantity} />
                ),
              },
              {
                header: "Unit Price",
                alignment: "right",
                render: (subLineItem) => (
                  <RoundedCurrency
                    amount={new Decimal(subLineItem.total).div(
                      subLineItem.quantity,
                    )}
                    creditType={subLineItem.credit_type}
                  />
                ),
              },
            ]}
          />
        </div>
      </ProductContainer>
    </div>
  );
};
