import React from "react";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";
import { Tooltip } from "design-system";
import Decimal from "decimal.js";

const formatNumberWithCommas = (number: Decimal) =>
  number.toNumber().toLocaleString("en-US", {
    maximumFractionDigits: 20, //Maximum number of digits allowed
  });

export function Quantity({
  quantity,
  rounded,
}: {
  quantity: string | Decimal;
  rounded?: boolean;
}) {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  const decimalQuantity = new Decimal(quantity);
  if (decimalQuantity.isNaN()) {
    return <>--</>;
  }

  const roundedValue = decimalQuantity.abs().lessThan(1)
    ? decimalQuantity.toSignificantDigits(2)
    : decimalQuantity.toDecimalPlaces(2);

  if (
    !roundedValue.equals(decimalQuantity) &&
    (isEmbeddableDashboard || rounded)
  ) {
    return (
      <span data-testid="tooltip">
        <Tooltip inline content={formatNumberWithCommas(decimalQuantity)}>
          {formatNumberWithCommas(roundedValue)}
        </Tooltip>
      </span>
    );
  } else {
    return <>{formatNumberWithCommas(decimalQuantity)}</>;
  }
}
